import React from "react";
import "../Product.css"
import { Container, Row, Col } from "react-bootstrap";
const Tracking= () => {
    return (
        <>
            <Container className="body mb-0">
                <Row className="values-row">
                    <Col md >
                        <div className="d-flex gap-4">
                            <img src="/images/Line5.png" alt="#" height="2px" style={{marginTop:"2%"}} />
                            <h2 className="itxt6">LOGISTICS</h2>
                        </div>

                        <p className="itxt2 worksans">Tracking</p>
                        <p className="itxt3 aeonik" >Track your shipment, get instant updates and notifications to help you plan for your business operations. Save hours of continous phone calls and view your entire supply chain as a whole. Notifications on any changes or delays are instantly shared.</p>
                        <button className="p-2 buttn aeonik">Check out <img src="/images/arrow-right.png" alt="arrow-right" width="45px" height="45px" className="icn"/></button>
                    </Col>
                    
                    <Col md>
                        <img src="/images/group15.png" width="90%" height="90%" alt="invoiceimage" />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Tracking;