import React from "react";
import { Container} from "react-bootstrap";
import "./MissionPage.css"
const MissionPage = () => {
  return (
    <>
      <Container className="body" style={{marginBottom:"10%"}}>
        <p className="mtxt worksans text-center">We are on <span className="spantext">a mission</span></p>
        <p className="aeonik mtxt2">To make trade seamless and transparent for <br /> exporters and importers in Africa</p>
        </Container>
    </>
  );
};

export default MissionPage;