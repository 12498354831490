import React from "react";
import "../Product.css"
import { Container, Row, Col } from "react-bootstrap";
const Logistics= () => {
    return (
        <>
            <Container className="body mb-0">
                <Row>
                    <Col md>
                        <img src="/images/LogisticsBook.png" width="90%" height="90%" alt="invoiceimage" />
                    </Col>
                    <Col md >
                        <div className="d-flex gap-4">
                            <img src="/images/Line5.png" alt="#" height="2px" style={{marginTop:"2%"}} />
                            <h2 className="itxt6">LOGISTICS</h2>
                        </div>

                        <p className="itxt2 worksans">Book</p>
                        <p className="itxt3 aeonik" >Get the best rates and most efficient trade routes to ensure timely delivery of your cargo. We manage your cargo from start to finish. Our team of customs experts help you clear your goods quickly and minimize import duties, delays and clumarage costs.</p>
                        <button className="p-2 buttn aeonik">Check out <img src="/images/arrow-right.png" alt="arrow-right" width="45px" height="45px" className="icn"/></button>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Logistics;