// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notfound{
color: #014D85;
text-align: center;
font-size: 250px;
font-weight: 600;
line-height: normal;
}
.notfound2{
color: #99C83C;
font-size: 250px;
font-weight: 600;
line-height: normal;
}


@media (max-width: 900px) {
.notfound , .notfound2{
    font-size: 100px;
}
}`, "",{"version":3,"sources":["webpack://./src/Components/Notfound/NotFound.css"],"names":[],"mappings":"AAAA;AACA,cAAc;AACd,kBAAkB;AAClB,gBAAgB;AAChB,gBAAgB;AAChB,mBAAmB;AACnB;AACA;AACA,cAAc;AACd,gBAAgB;AAChB,gBAAgB;AAChB,mBAAmB;AACnB;;;AAGA;AACA;IACI,gBAAgB;AACpB;AACA","sourcesContent":[".notfound{\ncolor: #014D85;\ntext-align: center;\nfont-size: 250px;\nfont-weight: 600;\nline-height: normal;\n}\n.notfound2{\ncolor: #99C83C;\nfont-size: 250px;\nfont-weight: 600;\nline-height: normal;\n}\n\n\n@media (max-width: 900px) {\n.notfound , .notfound2{\n    font-size: 100px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
