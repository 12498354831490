import React from "react";
import "../Product.css"
import { Container, Row, Col } from "react-bootstrap";
const SupplyChain = () => {
    return (
        <>
            <Container className="body mb-0">
                <Row className="values-row">
                    <Col md >
                        <div className="d-flex gap-4">
                            <img src="/images/Line5.png" alt="#" height="2px" style={{marginTop:"2%"}} />
                            <h2 className="itxt6"> TRADE FINANCE</h2>
                        </div>

                        <p className="itxt2 worksans">Supply Chain Finance</p>
                        <p className="itxt3 aeonik" >Let us finance your shipping fees, terminal and port charges as you focus on serving your customers</p>
                        <button className="p-2 buttn aeonik">Apply Now<img src="/images/arrow-right.png" alt="arrow-right" width="45px" height="45px" className="icn"/></button>
                    </Col>
                    <Col md>
                        <img src="/images/LogisticsFinance.png" width="90%" height="90%" alt="invoiceimage" />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default SupplyChain;