import { Container, Row, Col, ProgressBar, Card } from "react-bootstrap";
import "./About.css"
const About = () => {
    return (
        <>
            <Container fluid>
                <div className="about-heading">
                    <p className="worksans about-title">SME merchants in Africa deserve <span className="spantext worksans">more</span> and that’s what we want to give them.</p>
                    <p className="about-subtitle aeonik mt-5">We are on a mission to make trade seamless and transparent for cross border African merchants by providing cost efficient logistics and trade financing which has been historically unavilable to SMEs.</p>
                </div>
                <div className="mt-5 img-container" style={{ maxHeight: "100vh", marginBottom: "8%" }}>
                    <img src="/images/Photo.png" alt="#" width="100%" height="100%" className="abt-img"></img>
                    <div className="p-4" style={{
                        bottom: "15%", position: "relative"
                    }}>
                        <img src="/images/Subtract.png" alt="#" width="116px" height="116px" className="icon" ></img>
                    </div>
                </div>
                <div className="about-heading">
                    <p className="worksans about-title"> <span className="spantext worksans">Fixing trade finance</span> is the most significant impact we can have in the real world</p>
                    <p className="about-subtitle aeonik mt-5">In 2022 trade volumes exceeded $32 Trillion. The World Bank estimates that 80-90% of global trade needs financing. The giant hole in trade financing is SMEs. </p>
                    <div className="mt-5 img-container" style={{ maxHeight: "100vh", marginBottom: "8%"}}>
                        <img src="/images/Photo1.png" alt="#" width="110%" height="100%" className="abt-img"></img>
                        <div style={{
                            bottom: "109%",
                            float: "right",
                            position: "relative"
                        }}>
                            <img src="/images/Union.png" alt="#" width="116px" height="116px" className="icon" ></img>
                        </div>
                    </div>
                </div>
            </Container>
            <Row style={{ width: "100%" }} className="values-row">
                <Col md={5}>
                        <img src="/images/photo2.png" alt="#" width="100%;" height="527px" className="ab-img"/>
                   
                </Col>

                <Col>
                        <p className="about-title worksans"> <span className="spantext worksans">The values</span> that drive everything we do</p>
                        <p className=" aeonik about-subtitle">At Credify, we’re guided by a set of core values that inform everything we do from empowering SMEs to fostering innovation and driving social impact</p>
                  
                </Col>
            </Row>
            <div className="mt-5">
                <ProgressBar variant="success" now={30} className="progressbar" />
                <Row className="crdgroup2">
                    <Col md>
                    <Card className="crd3">
                            <Card.Img variant="top" src="/images/grid.png" className="cardimg1">
                            </Card.Img>
                            <Card.Body>
                                <Card.Title className="cardtitle aeonik text-left ">Integrity and transparency</Card.Title>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md>
                    <Card className="crd3">
                            <Card.Img variant="top" src="/images/grid1.png" className="cardimg1">
                            </Card.Img>
                            <Card.Body>
                                <Card.Title className="cardtitle aeonik text-left text-nowrap ">Customers first</Card.Title>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md>
                    <Card className="crd3">
                            <Card.Img variant="top" src="/images/grid2.png" className="cardimg1">
                            </Card.Img>
                            <Card.Body>
                                <Card.Title className="cardtitle aeonik text-left ">Collaboration</Card.Title>
                                
                            </Card.Body>
                        </Card>
                           
                    </Col>
                    <Col md>
                        <Card className="crd3">
                            <Card.Img variant="top" src="/images/grid3.png" className="cardimg1">
                            </Card.Img>
                            <Card.Body>
                                <Card.Title className="cardtitle aeonik text-left ">Teamwork</Card.Title>
                              
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>


            <div style={{ marginTop: "8%", marginBottom: "20%" }}>
                <p className="worksans about-title mt-5">Visit<span className="spantext"> Our Offices</span> <br /> around the world</p>
                <Row className="crdgroup">
                    <Col md className="crd2-div">
                        <Card className="crd2">
                            <Card.Img src="/images/ug-image.png">
                            </Card.Img>
                            <Card.ImgOverlay>
                                <Card.Text className="heading aeonik fs-6 fw-lighter" style={{ position: "absolute", bottom: 10, left: 20 }}>
                                    <span className="text-white float-bottom">Uganda<br/><span className="aeonik fs-6" style={{ color: "#FFB629" }}>Kampala</span></span>
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                        <div className="text-center heading aeonik fs-6">
                            <p><b>Uganda</b><br/>Crown House, 2nd Floor Suite 2A <br />Kampala Road, Uganda</p>
                        </div>
                    </Col>
                    <Col md className="crd2-div">
                        <Card className="crd2">
                            <Card.Img src="/images/china-image.png">
                            </Card.Img>
                            <Card.ImgOverlay>
                                <Card.Text className="heading aeonik fs-6 fw-lighter" style={{ position: "absolute", bottom: 10, left: 20 }}>
                                    <span className="text-white float-bottom">China <br />
                                    <span className="aeonik fs-6" style={{ color: "#FFB629" }}>Guangzhou</span></span> 
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                        <div className="text-center heading aeonik fs-6">
                            <p><b>China</b><br/>14/F Tower A, China International Center, No. 33 <br />Zhongshan 3rd road Yuexiu Dist, Guangzhou</p>
                        </div>
                    </Col>
                    <Col md className="crd2-div">
                        <Card className="crd2">
                            <Card.Img src="/images/usa-image.png">
                            </Card.Img>
                            <Card.ImgOverlay>
                                <Card.Text className="heading aeonik fs-6 fw-lighter" style={{ position: "absolute", bottom: 10, left: 20 }}>
                                    <span className="text-white float-bottom">USA <br/>
                                    <span className="aeonik fs-6" style={{ color: "#FFB629" }}>Delaware</span></span>
                                </Card.Text>
                            </Card.ImgOverlay>
                        </Card>
                        <div className="text-center aeonik heading fs-6">
                            <p><b>USA</b><br/>Delaware 651N Broad St Suite 201  <br />Middletown 19708, Newcastle</p>
                        </div>
                    </Col>

                </Row>
            </div>
        </>
    )
}
export default About;