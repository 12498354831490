import React from "react";
import "../Product.css"
import { Container, Row, Col } from "react-bootstrap";
const CustomsDuty = () => {
    return (
        <>
            <Container className="body mb-0">
                <Row>
                    <Col md>
                        <img src="/images/customsduty.png" width="90%" height="90%" alt="invoiceimage"/>
                    </Col>
                    <Col md >
                        <div className="d-flex gap-4">
                            <img src="/images/Line5.png" alt="#" height="2px" style={{marginTop:"2%"}} />
                            <h2 className="itxt6"> TRADE FINANCE</h2>
                        </div>

                        <p className="itxt2 worksans">Customs Duty</p>
                        <p className="itxt3 aeonik" >Leverage our extensive network of customs agents to get accurate and transparent customs assessments.</p>
                        <button className="p-2 buttn aeonik">Apply Now <img src="/images/arrow-right.png" alt="arrow-right" width="45px" height="45px" className="icn"/></button>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default CustomsDuty;