// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qheading {
    color: #565657;
    font-size: 72px;
    font-weight: 600;
    line-height: 104%;
    /* 74.88px */
    letter-spacing: -3.6px;
}

.qsub-title {
    color: #666C89;
    width: 550px;
    flex-shrink: 0;
    font-size: 22px;
    font-weight: 400;
    line-height: 151.523%;
    /* 33.335px */
}

.qtitle {
    color: #292D32;
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 151.523%;
    /* 24.244px */
}

.q-ans {
    color: #666C89;
    font-family: Aeonik;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.523%;
    /* 24.244px */
}

/* media queries */
@media (max-width: 900px) {
    .qsub-title{
        font-size: 16px;
        max-width: 100% !important;
        margin-bottom: 20%;
    }
    .qheading{
        font-size: 38px;
        max-width: 100% !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/FAQs/Faqs.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;IACrB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;IACrB,aAAa;AACjB;;AAEA,kBAAkB;AAClB;IACI;QACI,eAAe;QACf,0BAA0B;QAC1B,kBAAkB;IACtB;IACA;QACI,eAAe;QACf,0BAA0B;IAC9B;AACJ","sourcesContent":[".qheading {\n    color: #565657;\n    font-size: 72px;\n    font-weight: 600;\n    line-height: 104%;\n    /* 74.88px */\n    letter-spacing: -3.6px;\n}\n\n.qsub-title {\n    color: #666C89;\n    width: 550px;\n    flex-shrink: 0;\n    font-size: 22px;\n    font-weight: 400;\n    line-height: 151.523%;\n    /* 33.335px */\n}\n\n.qtitle {\n    color: #292D32;\n    font-family: Aeonik;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 151.523%;\n    /* 24.244px */\n}\n\n.q-ans {\n    color: #666C89;\n    font-family: Aeonik;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 151.523%;\n    /* 24.244px */\n}\n\n/* media queries */\n@media (max-width: 900px) {\n    .qsub-title{\n        font-size: 16px;\n        max-width: 100% !important;\n        margin-bottom: 20%;\n    }\n    .qheading{\n        font-size: 38px;\n        max-width: 100% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
