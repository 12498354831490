import React from "react";
import "../Product.css"
import { Container, Row, Col } from "react-bootstrap";
const InvoiceFinance = () => {
    return (
        <>
            <Container className="body mb-0">
                <Row>
                    <Col md>
                        <img src="/images/invoice.png" width="90%" height="90%" alt="invoiceimage"  />
                    </Col>
                    <Col md >
                        <div className="d-flex gap-4">
                            <img src="/images/Line5.png" alt="#" height="2px" style={{marginTop:"2%"}} />
                            <h2 className="itxt6"> TRADE FINANCE</h2>
                        </div>

                        <p className="itxt2 worksans">Invoice Finance</p>
                        <p className="itxt3 aeonik" >Unlocking trade finance helps SMEs to improve their unit economics by ordering more quantities, find more competitive prices and match seasonal demand cycles. </p>
                        <button className="p-2 buttn aeonik">Apply Now <img src="/images/arrow-right.png" alt="arrow-right" width="45px" height="45px" className="icn"/></button>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default InvoiceFinance;