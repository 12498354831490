// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lheading {
  color: #FFF;
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  /* 79.2px */
  letter-spacing: -3.6px;
}
.ltxt{
  width: 562px;
}
.bg-image{
  height: 100% !important;
}

.bttns:hover {
  background-color: #99C83C;
}

@media (max-width: 900px) {
  .lheading {
    font-size: 38px;
    letter-spacing: -1.9px;
    margin-top: 77px !important;
  }
 .bg-image{
  max-height: 532px !important;
 }
  .ltxt {
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    width: 285px;
    /* 137.5% */
  }

  .bttns {
    width: 154px !important;
    height: 54px !important;
    display: flex !important;
    padding: 13px 35px !important;
    align-items: flex-start;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 28px;
  }

  .demo {
    border: none !important;
    width: 100%;
    padding: 13px 0 !important;
  }

  .msk-container {
    min-height: 100vh !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Landing/Landing.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,WAAW;EACX,sBAAsB;AACxB;AACA;EACE,YAAY;AACd;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,eAAe;IACf,sBAAsB;IACtB,2BAA2B;EAC7B;CACD;EACC,4BAA4B;CAC7B;EACC;IACE,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,uBAAuB;IACvB,uBAAuB;IACvB,wBAAwB;IACxB,6BAA6B;IAC7B,uBAAuB;IACvB,0BAA0B;IAC1B,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,uBAAuB;IACvB,WAAW;IACX,0BAA0B;EAC5B;;EAEA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".lheading {\n  color: #FFF;\n  font-size: 72px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 110%;\n  /* 79.2px */\n  letter-spacing: -3.6px;\n}\n.ltxt{\n  width: 562px;\n}\n.bg-image{\n  height: 100% !important;\n}\n\n.bttns:hover {\n  background-color: #99C83C;\n}\n\n@media (max-width: 900px) {\n  .lheading {\n    font-size: 38px;\n    letter-spacing: -1.9px;\n    margin-top: 77px !important;\n  }\n .bg-image{\n  max-height: 532px !important;\n }\n  .ltxt {\n    color: #FFF;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 22px;\n    width: 285px;\n    /* 137.5% */\n  }\n\n  .bttns {\n    width: 154px !important;\n    height: 54px !important;\n    display: flex !important;\n    padding: 13px 35px !important;\n    align-items: flex-start;\n    font-size: 16px !important;\n    font-weight: 500;\n    line-height: 28px;\n  }\n\n  .demo {\n    border: none !important;\n    width: 100%;\n    padding: 13px 0 !important;\n  }\n\n  .msk-container {\n    min-height: 100vh !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
